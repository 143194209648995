import LineBreaker from "linebreak";
import { CharClass, LanguageClass } from "./util-text-classes.js";
import { createWbr, createThinSpace } from "./util-tags.js";
import { whitespaceRegex } from "./util-regex.js";
const insertSeparatorsToText = (currentText, nextText, options) => {
  const currentSegments = createSegments(currentText);
  const nextSegments = createSegments(nextText);
  const formattedText = currentSegments.reduce((accumulatedText, segment, index, array) => {
    const current = segment;
    const next = array[index + 1] || nextSegments[0];
    return accumulatedText + addSeparatorsToSegment(current, next, options);
  }, "");
  return formattedText;
};
const createSegments = (src) => {
  const segmenter = new Intl.Segmenter("ja-JP", { granularity: "word" });
  return Array.from(segmenter.segment(src), ({ segment }) => segment);
};
const addSeparatorsToSegment = (current, next = "", options) => {
  if (!next)
    return current;
  const addThinSpace = options.insertThinSpaces && shouldAddThinSpace(current, next);
  const breakable = isBreakable(current, next);
  if (addThinSpace) {
    const thinSpaceWidth = options.thinSpaceWidth ?? "";
    return current + createThinSpace(thinSpaceWidth, breakable);
  }
  if (options.useWordBreak && breakable) {
    return current + createWbr();
  }
  return current;
};
const shouldAddThinSpace = (current, next) => {
  if (whitespaceRegex.test(current) || whitespaceRegex.test(next))
    return false;
  return LanguageClass.shouldAddThinSpace(current, next) || CharClass.shouldAddThinSpace(current, next);
};
const isBreakable = (current, next) => {
  const combinedText = current.slice(-1) + next.slice(0, 1);
  const lb = new LineBreaker(combinedText);
  const breakOpportunity = lb.nextBreak();
  return breakOpportunity ? breakOpportunity.position === 1 : false;
};
var insert_separators_default = insertSeparatorsToText;
export {
  addSeparatorsToSegment,
  createSegments,
  insert_separators_default as default,
  isBreakable,
  shouldAddThinSpace
};
