import Typesetter from "palt-typesetting";
import "palt-typesetting/dist/typesetter.css";
import type { TypesettingOptions } from "palt-typesetting";
import "./module/typekit";
import "./style.css";

const options: Partial<TypesettingOptions> = {
  thinSpaceWidth: "0.15em",

  // 特定の文字間のカーニングルールを設定します。
  kerningRules: [
    { between: ["1", "9"], value: -50 },
    { between: ["イ", "ン"], value: 60 },
    { between: ["ス", "ト"], value: 120 },
    { between: ["フ", "ロ"], value: -50 },
    { between: ["イ", "ブ"], value: 20 },
    { between: ["ブ", "ラ"], value: -30 },
    { between: ["ラ", "リ"], value: 30 },
    { between: ["る", "。"], value: -40 },
  ],
};

const typesetter = new Typesetter(options);
typesetter.renderToSelector("#target");
