import * as util from "./util-regex.js";
const CharClass = {
  /**
   * セグメントが分離禁則文字で構成されているかどうかを判定します。
   * @param segment - 判定するテキストセグメント
   * @return 分離禁則文字で構成されている場合はtrue、そうでない場合はfalse
   */
  shouldNotBreak: (segment) => {
    return util.noBreakRulesRegex.test(segment);
  },
  /**
   * 2つのセグメント間に四分アキを追加すべきかを判断します。
   * @param current - 現在のセグメント
   * @param next - 次のセグメント
   * @return 四分アキを追加すべきかどうか
   */
  shouldAddThinSpace: (current, next) => {
    const spaceAfterRegex = new RegExp(
      `(${util.closingsRegex.source}|${util.commasRegex.source}|${util.periodsRegex.source})`
    );
    const settings = {
      spaceBefore: {
        regex: util.openingsRegex,
        hasSpaceBefore: true,
        hasSpaceAfter: false
      },
      spaceAfter: {
        regex: spaceAfterRegex,
        hasSpaceBefore: false,
        hasSpaceAfter: true
      },
      spaceBoth: {
        regex: util.middleDotsRegex,
        hasSpaceBefore: true,
        hasSpaceAfter: true
      }
    };
    for (const { regex, hasSpaceBefore, hasSpaceAfter } of Object.values(settings)) {
      if (hasSpaceBefore && regex.test(next) && !regex.test(current)) {
        return true;
      }
      if (hasSpaceAfter && regex.test(current) && !regex.test(next)) {
        return true;
      }
    }
    return false;
  },
  /**
   * 与えられた文字列の最初の文字が句読点に一致するかどうか判断します。
   * @param {string} segment - 判断対象の文字列
   * @return {boolean} 最初の文字が句読点であればtrueを返します。
   */
  startsWithPunctuation: (segment) => {
    const combinedRegex = new RegExp(`^[${util.periodsRegex.source}${util.commasRegex.source}]`);
    return combinedRegex.test(segment);
  }
};
const LanguageClass = {
  /**
   * セグメントがラテン文字で構成されているかどうかを判定します。
   * @param segment - 判定するテキストセグメント
   * @return ラテン文字で構成されている場合はtrue、そうでない場合はfalse
   */
  isLatin: (segment) => {
    return util.latinRegex.test(segment);
  },
  /**
   * セグメントが日本語文字で構成されているかどうかを判定します。
   * @param segment - 判定するテキストセグメント
   * @return 日本語文字で構成されている場合はtrue、そうでない場合はfalse
   */
  isJapanese: (segment) => {
    return util.japaneseRegex.test(segment);
  },
  /**
   * 現在のセグメントと次のセグメントの言語が異なるかどうかを判定します。
   * この関数は、一方が日本語で他方が非日本語の場合にtrueを返します。
   * @param current - 現在のテキストセグメント
   * @param next - 次のテキストセグメント
   * @return 両セグメント間で言語が異なる場合にtrueを返す
   */
  hasLanguageTransition: (current, next) => {
    return LanguageClass.isJapanese(current) !== LanguageClass.isJapanese(next);
  },
  /**
   * 2つのセグメント間に四分アキを追加すべきかを判断します。
   * @param current - 現在のセグメント
   * @param next - 次のセグメント
   * @return 四分アキを追加すべきかどうか
   */
  shouldAddThinSpace: (current, next) => {
    return LanguageClass.hasLanguageTransition(current, next) && !CharClass.startsWithPunctuation(next);
  }
};
export {
  CharClass,
  LanguageClass
};
