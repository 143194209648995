const latinRegex = /[\p{scx=Latin}0-9⁰¹²³⁴-⁾₀-₎¢¤$€£¥+−×÷=≠><≥≤±≈~¬∅∞∫∏∑√∂%‰@&.,:;!¡?¿'"‘’“”\-(){}[\]|/\\^_`*#\s]+/gu;
const japaneseRegex = /[\p{scx=Hiragana}\p{scx=Katakana}|ｦ-ﾟ\p{scx=Han}]+/u;
const openingsRegex = /（|［|｛|〔|〈|《|「|『|【|〘|〖|“|‘/;
const closingsRegex = /）|］|｝|〕|〉|》|」|』|】|〙|〗|”|’/;
const middleDotsRegex = /・|：|；/;
const commasRegex = /、|，/;
const periodsRegex = /。|．/;
const noBreakRulesRegex = new RegExp(
  `[${"\u2014\u2025\u2026\uFF3F"}${String.fromCharCode(9472)}-${String.fromCharCode(9599)}]+`,
  "g"
);
const whitespaceRegex = /[\s\u00A0]/;
export {
  closingsRegex,
  commasRegex,
  japaneseRegex,
  latinRegex,
  middleDotsRegex,
  noBreakRulesRegex,
  openingsRegex,
  periodsRegex,
  whitespaceRegex
};
