import { isBreakable } from "./insert-separators.js";
import { CharClass, LanguageClass } from "./util-text-classes.js";
import { applyWrapperStyle, applyLatinStyle, applyNoBreaksStyle, createKerning } from "./util-tags.js";
import * as util from "./util-regex.js";
const applyStyleToText = (currentNodeValue, nextNodeValue, options) => {
  if (currentNodeValue === " ") {
    return currentNodeValue;
  }
  return applyWrapperStyle(currentNodeValue, options.useWordBreak);
};
const applyStyleToSegment = (currentSegment, nextSegment, options) => {
  if (currentSegment === " ") {
    return currentSegment;
  }
  if (options.wrapLatin && LanguageClass.isLatin(currentSegment)) {
    return currentSegment.replace(util.latinRegex, (match) => applyLatinStyle(match));
  }
  if (options.noSpaceBetweenNoBreaks && CharClass.shouldNotBreak(currentSegment)) {
    return currentSegment.replace(util.noBreakRulesRegex, (match) => applyNoBreaksStyle(match));
  }
  return currentSegment;
};
const applyKerningToSegment = (currentSegment, nextSegment, options) => {
  const chars = [...currentSegment];
  const kernedChars = chars.map((currentChar, i) => {
    const nextChar = chars[i + 1] || nextSegment[0] || "";
    const kerningRule = options.kerningRules?.find(
      (rule) => rule.between[0] === currentChar && rule.between[1] === nextChar
    );
    if (kerningRule) {
      const kerningValue = typeof kerningRule.value === "number" ? kerningRule.value : parseInt(kerningRule.value, 10);
      const breakable = options.useWordBreak ? false : isBreakable(currentChar, nextChar);
      return currentChar + createKerning(kerningValue, breakable);
    }
    return currentChar;
  });
  return kernedChars.join("");
};
export {
  applyKerningToSegment,
  applyStyleToSegment,
  applyStyleToText
};
