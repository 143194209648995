const prefix = "typeset";
const createWbr = () => {
  return "<wbr>";
};
const createThinSpace = (thinSpaceWidth, breakable) => {
  const content = "";
  const className = `${prefix}-thin-space`;
  return createSpacer(content, className, thinSpaceWidth, breakable);
};
const createKerning = (kerningValue, breakable) => {
  if (kerningValue === 0)
    return "";
  const content = "";
  const className = `${prefix}-kerning`;
  if (kerningValue < 0) {
    const emValue = kerningValue / 1e3 / 2 + "em";
    const style = `margin: ${emValue};`;
    return createStyledSpan(content, className, style);
  } else {
    const emValue = kerningValue / 1e3 + "em";
    return createSpacer(content, className, emValue, breakable);
  }
};
const applyWrapperStyle = (text, useWordBreak) => {
  const wrapperName = `${prefix} ${prefix}-wrapper`;
  const wordBreakName = `${prefix}-word-break`;
  const className = useWordBreak ? `${wrapperName} ${wordBreakName}` : wrapperName;
  return createStyledSpan(text, className);
};
const applyLatinStyle = (segment) => {
  const className = `${prefix}-latin`;
  return createStyledSpan(segment, className);
};
const applyNoBreaksStyle = (segment) => {
  const className = `${prefix}-no-breaks`;
  return createStyledSpan(segment, className);
};
const createStyledSpan = (content, className, style = "", attr = "") => {
  const styleAttr = style ? ` style="${style}"` : "";
  return `<span class="${className}"${styleAttr}${attr}>${content}</span>`;
};
const createSpacer = (content, className, value, breakable) => {
  const style = `letter-spacing: ${value};`;
  const data = ` data-content="${breakable ? " " : "&nbsp;"}"`;
  return createStyledSpan(content, className, style, data);
};
export {
  applyLatinStyle,
  applyNoBreaksStyle,
  applyWrapperStyle,
  createKerning,
  createThinSpace,
  createWbr
};
